import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from './components/common/Layout';
import News from './components/News';
import Support from './components/Support';
import Signup from './components/Signup';
import Login from './components/Login';
import Information from './components/Information';
import Courses from './components/Courses';
import CourseTemplate from './components/CourseTemplate';
import NewHome from './components/NewHome';
import Pricing from './components/Pricing';
import AdminUsers from './components/AdminUsers';
import { RequireAdmin } from './context/RequireAdmin';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={ <NewHome/> } />
            <Route path='/news' element={<News />}/>
            <Route path='/support' element={<Support />}/>
            <Route path='/signup' element={<Signup/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/information' element={<Information/>}/>
            <Route path='/pricing' element={<Pricing/>}/>
            <Route path='/courses' element={<Courses/>}/>
            <Route path='/courses/:id' element={<CourseTemplate/>}/>
            <Route path='/admin-users' element={<RequireAdmin><AdminUsers/></RequireAdmin>}/>
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;