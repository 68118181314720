import './styles/signup.css'
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createUserWithEmailAndPassword, updateProfile, onAuthStateChanged } from 'firebase/auth';
import {auth} from '../firebase-config'
import axios from 'axios';
import { endpoint } from '../context/Endpoint';

export default function Signup() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [profileData, setProfileData] = useState<any>();

    const onLoadPage = () => {
        onAuthStateChanged(auth, async(user)=>{
          if (user)
              navigate('/', { replace: true });
        });
      }

    const onChangeData = (name: string, value: any) => {
        let updatedData: any = { ...profileData };
        updatedData[name] = value;
        setProfileData(updatedData);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (profileData?.name === '' || profileData?.name === ' ') {
            console.error('introduce un nombre de usuario');
            return;
        }
        if (profileData?.name === '' || profileData?.name === ' ') {
            console.error('introduce un apellido de usuario');
            return;
        }
        if (profileData?.email === '' || profileData?.email === ' ') {
            console.error('introduce un correo elecronico')
            return;
        }

        if(profileData?.password.length >= 6){
            if(profileData?.password === profileData?.repeatPassword){
                try{
                    await createUserWithEmailAndPassword(auth, profileData?.email, profileData?.password);
                    if(auth?.currentUser){
                        await updateProfile(auth?.currentUser, { displayName: `${profileData?.name} ${profileData?.lastname}`});
                        RegisterUser();
                        navigate('/', {replace: true})
                    }
                } catch(e: any){
                    console.error(e);
                }
            } else{
                console.error("Las contraseñas no coinciden");        
            }
        } else {
            console.error("La contraseña debe tener al menos 6 caracteres");
        }
    }
    const RegisterUser = async() =>{
        await axios.post(`${endpoint}/users`, {
            id:auth.currentUser?.uid,
            email:profileData?.email,
            isAdmin: false, 
            contact:profileData?.contact, 
            name: `${profileData?.name} ${profileData?.lastname}`
        });
    }

    useEffect(()=> {onLoadPage()}, [])
    return (
        <main className="signup">
            <h1>{t("SignupPage.Welcome")}</h1>
            <p> {t("SignupPage.HaveAccount")} <a href="login">{t("SignupPage.Here")}</a>{" "}
            </p>
            <form className="data" onSubmit={handleSubmit}>
                <div className="names">
                    <input className="data-input" type="text" name="name" placeholder={t("SignupPage.Name")} id="name" onChange={(e) => { onChangeData("name", e.target.value) }} />
                    <input className="data-input" type="text" name="lastname" placeholder={t("SignupPage.Surname")} id="lastname" onChange={(e) => { onChangeData("lastname", e.target.value) }} />
                </div>
                <input className="data-input" type="text" name="contact" placeholder={t("SignupPage.Contact")} onChange={(e) => { onChangeData("contact", e.target.value) }} />
                <input className="data-input data-email" type="text" name="email" placeholder={t("SignupPage.Email")} id="email" onChange={(e) => { onChangeData("email", e.target.value) }} />
                <div className="passwords">
                    <input className="data-input" type="password" name="password" placeholder={t("SignupPage.Password")} id="password" onChange={(e) => { onChangeData("password", e.target.value) }} />
                    <input className="data-input" type="password" name="repeat-password" placeholder={t("SignupPage.RepeatPassword")} id="repeat-password" onChange={(e) => { onChangeData("repeatPassword", e.target.value) }} />
                </div>
                <input type="submit" value={t("SignupPage.Signup")} />
            </form>
        </main>
    );
}