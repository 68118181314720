import {PayPalButtons} from '@paypal/react-paypal-js';

interface PaypalInterface {
    totalValue:number;
    invoice:string;
    onSuccess:(value:any)=>void;
    onCancelled:(value:any)=>void;
}

const PaypalButton: React.FC<PaypalInterface> = (props) => {
    return (
        <PayPalButtons
            createOrder={(data, actions) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [{
                        description: props.invoice,
                        amount: {
                            currency_code: "USD",
                            value: props.totalValue.toString(),
                        },
                    }],
                });
            }}
            onApprove={async (data, actions) => {
                const order = await actions.order?.capture();
                props.onSuccess(order);
            }}
            onCancel={async (data, actions) => {
                console.log(data);
                console.log(actions);
                props.onCancelled("");
            }}
        />
    );
}

export default PaypalButton;