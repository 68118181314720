import './styles/main.css';
import { useTranslation } from 'react-i18next';

export default function News() {
    const { t } = useTranslation();
    return (
                <div className='area' style={{ background: "#d3d6d9" }}>
                    <div className="home-area">
                        <br></br>
                        <div className='simulator-title'>{t("Congress 2023")}</div>
                        <div className='simulations-image'>
                            <img style={{ "width": "100%", "maxWidth": "750px" }} src={process.env.PUBLIC_URL + "/img/simulator3.png"} />
                            <div>
                                <img style={{ "width": "100%", "maxWidth": "750px" }} src={process.env.PUBLIC_URL + "/img/simulator4.png"} />
                                <br></br>
                                <img style={{ "width": "100%", "maxWidth": "750px" }} src={process.env.PUBLIC_URL + "/img/simulator1.png"} />
                            </div>
                        </div>
                        <br></br>
                    </div>
                </div>
    );
}
