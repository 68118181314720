import './styles/main.css';
import { useEffect, useState } from 'react'
import './styles/CourseDescription.css'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { endpoint } from '../context/Endpoint';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';
import { IsAdmin } from '../context/IsAdmin';
import Loading from '../context/Loading';

export default function Information() {
    const { t } = useTranslation();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [courses, setCourses] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const onLoadPage = () => {
        axios.get(`${endpoint}/courses`).then((response) => {setCourses(response.data); setIsLoading(false)}).catch((error) => console.error(error));
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await IsAdmin().then(response => { setIsAdmin(response); });
            }
        });
    }
    const addCourse = () => {
        let newCourse ={title:"New Course", subtitle:"new course", levels:[]}; 
        axios.post(`${endpoint}/courses`, newCourse).then((res) => {
            setCourses([
                ...courses, 
                {...newCourse, id:res.data}
            ]);
        })
        
    }
    const deleteCourse = (id:string) =>{
        axios.delete(`${endpoint}/courses/${id}`).then(()=>{window.location.reload()})
    }

    useEffect(()=> {onLoadPage()}, [])
    if(isLoading) return <Loading/>
    return (
        <div className='area'>
            <div className='welcomeArea'>
                <br></br>
                <div>{t("Courses")}</div>
                {
                    courses.map((c:any) => (
                            <div className='lastcourse'>
                                <div className='videoThumbnail'>
                                    <div>
                                        <img width="100%" height="100%" alt="Thumbnail" style={{ borderRadius: "25PX" }} src={ c?.thumbnail ?? process.env.PUBLIC_URL + "/course-template.gif"} />
                                        <div className='text-card'>
                                            <a href= {"/courses/" + c.id}>
                                                <div>
                                                    <p style={{color:'white'}}>{c?.title}</p>
                                                </div>
                                            </a>
                                        </div>
                                        { isAdmin && <img className='img-card' src={process.env.PUBLIC_URL + "/img/delete.png"} alt="" onClick={()=>{deleteCourse(c.id)}}/>}
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
                {isAdmin && (
                    <div className='add-course' onClick={addCourse}>
                        <img src={process.env.PUBLIC_URL + "/img/add_icon.png"} alt="" />
                        <span>{t("AddCourse")}</span>
                    </div>
                )}
            </div>
        </div>
    );
}