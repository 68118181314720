import axios from 'axios';
import './styles/pricing.css'
import { useState, useEffect } from "react";
import { endpoint } from '../context/Endpoint';
import Loading from '../context/Loading';
import { MultiSelect } from 'react-multi-select-component';
import {Option} from 'react-multi-select-component'
import PriceCard from './layout/PriceCard';
import { IsAdmin } from '../context/IsAdmin';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';

export default function Pricing(){
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [prices, setPrices] = useState<any[]>([]);
    const [newPrice, setNewPrice] = useState<any>({});
    const [sectionsOptions, setSectionsOptions] = useState<Option[]>([]);
    const [selected, setSelected] = useState([]);

    const OnLoadPage = () => {
        axios.get(`${endpoint}/pricing`)
        .then(res => setPrices(res.data))
        .catch(err => console.error(err));

        axios.get(`${endpoint}/courses`)
        .then((response) => { setSectionsOptions([...response.data.map((x: any) => ({ label: x?.title, value: x.id }))]); })
        .catch((error) => console.error(error))
        .finally(()=> {setIsLoading(false);});
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await IsAdmin().then(response => { setIsAdmin(response); });
            }
        });
    }
    
    const EditNewPrice = (parameter:string, value:any) => setNewPrice({...newPrice, [parameter]:value});
    const RegisterPrice = () => {
        axios.post(`${endpoint}/pricing`, newPrice).then((res)=>{
            setPrices([...prices, newPrice]);
            setNewPrice({});
        }).catch(err => console.error(err));
    }
    const SavePrice = (price:any) => { 
        console.log(price);
        
        axios.put(`${endpoint}/pricing/${price?.id}`, price)
        .then(res => {
            console.log(res.data);
            console.log("Se actualizo correctamente");
            
        })
        .catch(err => console.error(err));
    }
    const DeletePrice = (price:any) => {
        console.log("delete");
        
        axios.delete(`${endpoint}/pricing/${price.id}`)
        .then(()=> window.location.reload())
        .catch((err) => console.error(err));
    }
    const Purchase = () => { }
    const ContactUs = () =>{ }

    useEffect(()=>{EditNewPrice("courses", selected.map((x:any) => x.value))}, [selected]);
    useEffect(OnLoadPage, []);

    if(isLoading) return Loading();
    return <main className="pricing">
        
        {
            prices?.map((p:any) => ((isAdmin || p.visibility) && <PriceCard  OnSave={SavePrice} OnDelete={()=>{DeletePrice(p)}} data={p} allSections={sectionsOptions}/> ))
        }
        <div className="price">                    
            <div className='get'>
                <span>{"Educacional"}</span>
                <span>{`Hablemos`}</span>
                <span></span>
                <span>{`Subscripción diseñada para instituciones educativas`}</span>
            </div>
            <input type="button" value="Contáctanos" onClick={ ContactUs } />
        </div>
        {
            isAdmin && <div className="create-price">
                <div className='create'>
                    <span>Crear un nuevo plan</span>
                    <input type="text" name='title' placeholder='Titulo' value={newPrice?.title ?? ""} onChange={(e)=> { EditNewPrice(e.target.name, e.target.value)}} />
                    <input type="text" name='price' placeholder='Precio ($USD)' value={newPrice?.price ?? ""} onChange={(e)=> {EditNewPrice(e.target.name, e.target.value)}} />
                    <input type="text" name='duration' placeholder='Duracion (Meses)' value={newPrice?.duration ?? ""} onChange={(e)=> {EditNewPrice(e.target.name, e.target.value)}} />
                    <input type="text" name='description' placeholder='Descripcion' value={newPrice?.description ?? ""} onChange={(e)=> {EditNewPrice(e.target.name, e.target.value)}} />
                    <div className='multi-select'>
                        <MultiSelect options={sectionsOptions} value={selected} onChange={setSelected} labelledBy="Select" />                        
                    </div>
                    
                    <div>
                        <span>Visible: </span>
                        <input type="checkbox" name='visibility' value={newPrice?.visibility ?? ""} onChange={(e)=> { EditNewPrice(e.target.name, e.target.value) }} />
                    </div>
                </div>
                <input type="button" value="Enviar" onClick={RegisterPrice} />
            </div>
        }
    </main>
}