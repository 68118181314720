import axios from 'axios';
import './styles/NewHome.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function NewHome() {
    const { t } = useTranslation();
    const [suggestion, setSuggestion] = useState<any>({});

    const editSuggestion = (parameter:string, value:any) => {
        let prevSuggestion = {...suggestion};
        prevSuggestion[parameter] = value; 
        setSuggestion(prevSuggestion);
    }
    const sendSuggestion = () => {
        axios.post(`https://api.web3forms.com/submit`, {...suggestion, 'Platform':'ORVO', access_key:"67b90aa5-4a67-4fcc-88df-c3c5b620f0f6"})
        .then(() => {
            setSuggestion({});
            alert("Tu mensaje fue enviado.")
        }).catch((error) => console.error(error));
    }
    const openWhatsApp = async() => {
        const message = suggestion.message ?? "Hola, ¡me gustaría hablar con un administrador sobre FUVE!"; 
        const whatsappURL = `https://wa.me/${"+5213332475398"}?text=${message}`;
        window.open(whatsappURL, '_blank');
    }

    return (
        <div className='area'>
            <div className="floating" onClick={openWhatsApp}>
                <img src={process.env.PUBLIC_URL + "/whatsapp.webp"} alt="" />
            </div>
            <section>
                <video className='video-home' src="https://thumbnails.fuve.app/Orvo_1.mp4" autoPlay loop muted controls={true} controlsList="nodownload" />
            </section>
            <section>
                <div className='first-page'>
                    <div>
                        <p style={{ fontSize: "75px", margin: "0", fontWeight: "700" }}>
                            {t("NewHome.FirstPage.Entra")}<br />
                            {t("NewHome.FirstPage.conRealidad")}<br />
                            {t("NewHome.FirstPage.Virtual")}<br />
                        </p>
                        <p style={{ fontSize: "55px", margin: "0", fontWeight: "300" }}>
                            {t("NewHome.FirstPage.Aprendetecnicas")}<br />
                            {t("NewHome.FirstPage.MedianteSimulacion")}<br />
                        </p>
                        <a href="/" style={{ color: "white" }}>
                            <div className='button-green'>
                                <p>{t("NewHome.FirstPage.Loquiero")}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className='second-page'>
                    <br />
                    <img style={{ "width": "100%", "maxWidth": "70px" }} src={process.env.PUBLIC_URL + "/img/orvo_logo_abajo.png"} />
                    <h1>{t("NewHome.SecondPage.Desarrolladopara")}</h1>
                    <img style={{ "width": "100%", "maxWidth": "150px" }} src={process.env.PUBLIC_URL + "/img/oculus.png"} />
                    <img style={{ "width": "100%", "maxWidth": "1350px" }} src={process.env.PUBLIC_URL + "/img/banner_oculus.png"} />
                    <div>
                        <div className='gray-card'>
                            <img className="gray-card-icon" src={process.env.PUBLIC_URL + "/img/aprendizaje.png"} />
                            <p style={{ fontWeight: "600" }}>{t("NewHome.SecondPage.Aprendizaje")}</p>
                            <p>{t("NewHome.SecondPage.Inmersivo")}</p>
                        </div>
                        <div className='gray-card'>
                            <img className="gray-card-icon" src={process.env.PUBLIC_URL + "/img/elfuturodelamedicina.png"} />
                            <p style={{ fontWeight: "600" }}>{t("NewHome.SecondPage.ElFuturo")}</p>
                            <p>{t("NewHome.SecondPage.Delamed")}</p>
                        </div>
                        <div className='gray-card'>
                            <img className="gray-card-icon" src={process.env.PUBLIC_URL + "/img/practicadesdecasa.png"} />
                            <p style={{ fontWeight: "600" }}>{t("NewHome.SecondPage.Practica")}</p>
                            <p>{t("NewHome.SecondPage.Desdecasa")}</p>
                        </div>
                        <div className='gray-card'>
                            <img className="gray-card-icon" src={process.env.PUBLIC_URL + "/img/conviertete.png"} />
                            <p style={{ fontWeight: "600" }}>{t("NewHome.SecondPage.Conviertete")}</p>
                            <p>{t("NewHome.SecondPage.Enexp")}</p>
                        </div>
                    </div>
                    <a href="/support">
                        <div className='button-green'>
                            <p style={{ color: "white", fontWeight: "300" }}>{t("NewHome.SecondPage.InformacionTec")}</p>
                        </div>
                    </a>
                    <br />
                </div>
            </section>

            <section>
                <div className='third-page'>
                    <br />
                    <img src={process.env.PUBLIC_URL + "/img/3dicon.png"} style={{ maxWidth: "100px" }} />
                    <div>
                        <p style={{ fontSize: "75px", margin: "0", fontWeight: "700" }}>
                            {t("NewHome.ThirdPage.Practicassin")}<br />
                            {t("NewHome.ThirdPage.Desdedonde")}<br />
                        </p>
                        <p style={{ fontSize: "55px", margin: "0", fontWeight: "300", textAlign: "center" }}>
                            {t("NewHome.ThirdPage.Contamoscon")}<br />
                            {t("NewHome.ThirdPage.Seano")}<br />
                            {t("NewHome.ThirdPage.Nuestradif")}<br />
                            {t("NewHome.ThirdPage.Simuladorutil")}
                        </p>
                    </div>
                    <img src={process.env.PUBLIC_URL + "/img/dravisor.png"} />
                </div>
            </section>

            <section>
                <div className='five-page'>
                    <div className='column'>
                        <div className='row'>
                            <div className='column'>
                                <img src={process.env.PUBLIC_URL + "/img/orvologonegro.png"} style={{ maxWidth: "100px" }} />
                                <br />
                                <p style={{ fontWeight: "400", fontSize: "45px", textAlign: "center" }}>
                                    {t("NewHome.FourPage.Lomascer")}<br />
                                    {t("NewHome.FourPage.Unain")}<br />
                                    {t("NewHome.FourPage.quirurgica")}
                                </p>
                                <br />
                                <p style={{ fontWeight: "400", fontSize: "25px", textAlign: "center" }}>
                                    {t("NewHome.FourPage.Lasinter")}<br />
                                    {t("NewHome.FourPage.Principalm")}<br />
                                    {t("NewHome.FourPage.Fetoscopi")}<br />
                                    {t("NewHome.FourPage.desafiantes")}<br />
                                    {t("NewHome.FourPage.adquirir")}
                                </p>
                            </div>
                            <img src={process.env.PUBLIC_URL + "/img/doc1cuadrado.png"} style={{ width: "100%", maxWidth: "1000px" }} />
                        </div>
                        <div className='row'>
                            <img src={process.env.PUBLIC_URL + "/img/cuadradodoctor2.png"} style={{ width: "100%", maxWidth: "1000px" }} />
                            <div className='column'>
                                <img src={process.env.PUBLIC_URL + "/img/orvologonegro.png"} style={{ maxWidth: "100px" }} />
                                <br />
                                <p style={{ fontWeight: "400", fontSize: "45px", textAlign: "center" }}>
                                    {t("NewHome.FourPage.Medicinaala")}<br />
                                    {t("NewHome.FourPage.Vanguardia")}<br />
                                    {t("NewHome.FourPage.Tecnologica")}
                                </p>
                                <br />
                                <p style={{ fontWeight: "400", fontSize: "25px", textAlign: "center" }}>
                                    {t("NewHome.FourPage.Estesoft")}<br />
                                    {t("NewHome.FourPage.Utilizandomod")}<br />
                                    {t("NewHome.FourPage.Avanzadauna")}<br />
                                    {t("NewHome.FourPage.Completarla")}<br />
                                    {t("NewHome.FourPage.Experienciapr")}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {false && <section>
                <div className='second-page' style={{ backgroundColor: "#2f0171" }}>
                    <br />
                    <img style={{ "width": "100%", "maxWidth": "90px" }} src={process.env.PUBLIC_URL + "/img/orvo_logo_abajo.png"} />
                    <h1 style={{ textAlign: "center", fontSize: "35px", fontWeight: "400" }}>
                        {t("NewHome.FivePage.Cursosmaspop")}<br />
                        {t("NewHome.FivePage.Nuestrosusu")}
                    </h1>
                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                        <div className='purple-card' id='curso1'>
                            <div>
                                {t("NewHome.FivePage.Simulador")}<br />
                                {t("NewHome.FivePage.DeFeto")}
                            </div>
                        </div>
                        <div className='purple-card' id='curso2'>
                            <div>
                                {t("NewHome.FivePage.Broncos")}<br />
                                {t("NewHome.FivePage.Fetal")}
                            </div>
                        </div>
                        <div className='purple-card' id='curso3'>
                            <div>
                                {t("NewHome.FivePage.OclusionTra")}<br />
                                {t("NewHome.FivePage.Fetosco")}
                            </div>
                        </div>
                        <div className='purple-card' id='curso4'>
                            <div>
                                {t("NewHome.FivePage.Cococacionde")}
                                {t("NewHome.FivePage.Pleuro")}
                            </div>
                        </div>


                    </div>
                    <a href="/information">
                        <div className='button-green'>
                            <p style={{ color: "white", fontWeight: "300" }}>{t("NewHome.FivePage.Vertodos")}</p>
                        </div>
                    </a>
                    <br />
                </div>
            </section>
            }
            <section>
                <div className='seven-page'>
                    <div className='card-container-info'>
                        <div>
                            <h1>{t("NewHome.SixPage.Quierosaber")}</h1>
                            <div className='card-info'>
                                <h1>{t("NewHome.SixPage.Estamospara")}</h1>
                                <div className='contact-us-form'>
                                    <div>
                                        <br />
                                        <div className="section-title">
                                                <input className="form-styling" type="text" name="Nombre" value={suggestion?.Nombre ?? ""} placeholder={t("NewHome.SixPage.NombreCom")} required id="home-input" onChange={(e) => editSuggestion(e.target.name, e.target.value) } />
                                                <input className="form-styling" type="email" name="Email" value={suggestion?.Email ?? ""} placeholder={t("NewHome.SixPage.Email")} required id="home-input"  onChange={(e) => editSuggestion(e.target.name, e.target.value) } />
                                                <textarea className="form-styling" name="Pregunta" value={suggestion?.Pregunta ?? ""} placeholder={t("NewHome.SixPage.Tumens")} style={{ height: "120px" }} required id="home-input"  onChange={(e) => editSuggestion(e.target.name, e.target.value) } />
                                                <input type="hidden" name="_template" value="box"></input>
                                                <div className="button-container">
                                                    <div>
                                                        <button type="button" className="button-green" style={{ color: "white", borderStyle: "none" }} onClick={sendSuggestion}>
                                                        {t("NewHome.SixPage.Enviarmen")}
                                                        </button>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-container-info-gray'>
                        <div>
                            <div className='card-info-gray'>
                                <h1>{t("NewHome.SixPage.Prefieres")}</h1>
                                <p>+52 1 33 3247 5398</p>
                                <p>soporte@medicinafetalmexico.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    );
}