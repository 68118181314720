import './styles/login.css'
import { useState, useEffect } from "react";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase-config'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { endpoint } from '../context/Endpoint';

export default function Login() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    let to = '/';

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');   

    const onLoadPage = () => {
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                navigate(to, { replace: true });
            }
        });
    }
    
    const googleSubmit = async()=>{
        await signInWithPopup(auth, new GoogleAuthProvider());
        RegisterUser();
    }
    const RegisterUser = async() =>{ 
        await axios.get(`${endpoint}/users/${auth.currentUser?.uid}`).catch( async() => { 
            await axios.post(`${endpoint}/users`, {
                id:auth.currentUser?.uid,
                email:auth.currentUser?.email,
                isAdmin: false
            });
         });
      }
    const handleSubmit = async()=>{
        if(email !== '' && password !== ''){
            try{
                await signInWithEmailAndPassword(auth, email, password);
                navigate(to, { replace: true });
            } catch(e:any){
                if(e.code == "auth/user-not-found"){
                    //setIsOpenUserNotFoundModal(true);
                    console.error("user not founded xd");
                } else if(e.code == "auth/wrong-password") {
                    console.error("wrong password xd");
                    //setIsOpenWrongPasswordModal(true);
                    
                } else {
                    console.error(e);
                }
            }
        }
    }
    
    useEffect(()=> {onLoadPage()}, [])
    return(
        <main className="login">
            <div className="signup-button btn" onClick={googleSubmit}>
                <img src={process.env.PUBLIC_URL + "google.png"} alt="" />
                <span>{t("LoginPage.LoginGoogle")}</span>
            </div>

            <div className="login-title lineText">{t("LoginPage.Or")}</div>

            <form className='login-data'>
                <input className="data-input data-email" type="text" name="email" placeholder={t("LoginPage.Email")} id="email" onChange={(ev) => {setEmail(ev.target.value)}} />
                <input className="data-input" type="password" name="password" placeholder={t("LoginPage.Password")} id="password" onChange={(e)=> {setPassword(e.target.value)}} />
                <a href="password-recovery">{t("LoginPage.ForgetPassword")}</a>
            </form>

            <div className="signup-email btn" onClick={handleSubmit}> {t("LoginPage.Login")}</div>
            <div className='signup-here btn'>{t("LoginPage.HaveAccount")} 
                <a href="signup">{t("LoginPage.Here")}</a>
            </div>
        </main>
    )
}
