import "./styles/CourseTop.css";
import { useParams } from "react-router-dom";
import { NavigateFunction } from "react-router-dom";
import { useTranslation } from "react-i18next";
function CourseTop() {
    const { t } = useTranslation();
    
    return (
        <div className="background">
            <div className="lastcourse-courses-page">
                <div className="videoThumbnail-courses-page">
                    <div className="mediaArea-courses-page">
                        <video
                            width="100%"
                            height="auto"
                            controls
                            controlsList="nodownload"
                            autoPlay
                            muted
                            loop>
                            <source src="https://thumbnails.fuve.app/Orvo_1.mp4" type="video/mp4"></source>
                        </video>
                    </div>
                </div>
            </div>
            <div className="right-area">
                <div className="vertical-grid-container-courses-page">
                    <div className="title-case">title</div>
                    <div className="subtitle-case"> subtitle </div>
                    <div className="button-case">
                        <div className="text-button-container"><div>Hola</div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseTop;
