import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

let firebaseConfig = {
    apiKey: "AIzaSyBxXB8j-BFAFlYnHofxnn9h7POwQLNOQDM",
    authDomain: "orvo-mfm.firebaseapp.com",
    projectId: "orvo-mfm",
    storageBucket: "orvo-mfm.appspot.com",
    messagingSenderId: "1048055686569",
    appId: "1:1048055686569:web:304fd6a67a4952f39610b8",
    measurementId: "G-CZ2LXSENET"
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);