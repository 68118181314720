import { useState, useEffect } from 'react';
import './styles/admin-users.css';
import axios from 'axios';
import { Timestamp } from "firebase/firestore";
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useTranslation } from 'react-i18next';
import { endpoint } from '../context/Endpoint';
import Loading from '../context/Loading';
import Toggle from './layout/Toggle';

export default function AdminUsers(){
    const {t} = useTranslation();
    const [userQuery, setUserQuery] = useState("");
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [pricing, setPricing] = useState<any[]>([]);
    const [user, setUser] = useState<User|null>(null);
    const [loading, setLoading] = useState(true);
    
    const onLoadPage = async() => {
        await axios.get(`${endpoint}/pricing`).then(response => setPricing(response.data)).catch(error => console.error(error));
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                setUser(user);
            }
        });
        setLoading(false);
    }

    const onChangeUserQuery = (e:string) => { setUserQuery(e); }
    const searchUser = async () => {
        setLoading(true);
        if (userQuery.includes('@')) {            
            await axios.get(`${endpoint}/users`).then((response)=>{
                const filteredUser = response.data.filter((x:any) => x.email  === userQuery)[0];
                if (typeof filteredUser === 'undefined')
                    alert("El correo introducido no se encuentra entre los usuarios registrados.")
                setCurrentUser(filteredUser);
                console.log(filteredUser);
                
            })
        } else {
            await axios.get(`${endpoint}/users/${userQuery}`)
            .then((response)=>{ setCurrentUser(response.data); })
            .catch(()=>{  
                alert("El uid introducido no se encuentra entre los usuarios registrados.");
                setCurrentUser(null);
            })
        }
        setLoading(false);
    }
    const onChangeData = (name:string, value:any) => {
        let updatedData:any = { ...currentUser };
        updatedData[name] = value;
        setCurrentUser(updatedData);
    }
    useEffect(()=>{console.log(currentUser)}, [currentUser]);
    const onChangeSubscriptionData = (subscription:any, name:string, value:any) => {
        let prevData = {...currentUser};
        prevData.subscriptions?.forEach((s:any) => {
            if(s.subscription_id === subscription.subscription_id){
                s[name] = value;
                return;
            }
        });
        setCurrentUser(prevData);
    }
    const addSubscription = () => {
        let updatedData:any = { ...currentUser };    
        
        if(typeof updatedData.subscriptions === 'undefined')
            updatedData.subscriptions = [];
        
        let newDate = Timestamp.fromDate(addDays(new Date(Timestamp.now().toMillis()), 1));        
        
        updatedData?.subscriptions.push({
                provider:user?.email || "admin",
                plan_name:"FUVE - Admin - Provided",
                product_id:pricing[0].id,
                subscription_id: crypto.randomUUID(),
                next_billing_time:newDate
            }
        )
        
        setCurrentUser(updatedData);  
    }
    const toDate = (date:Timestamp) => { 
        if(typeof date === 'undefined')
            return;
        try{
            return new Timestamp(date.seconds, date.nanoseconds).toDate().toISOString().slice(0, 19); 
        } catch(error){
            console.error(error);            
        }
    }
    const formatDate = (timestamp: number) => {
      const date = new Date(timestamp); 
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      
      return `${day}/${month}/${year}`;
  }
    const addDays = (date:any, days:number):Date => {
        date.setDate(date.getDate() + days);
        return date;
    }
    const getStatus = (date:Timestamp) => date?.seconds > Timestamp.now().seconds ? "ACTIVE" : "EXPIRED";
    const updateUser = () => { axios.put(`${endpoint}/users/${currentUser?.id}`, currentUser).then(()=>{ window.location.reload() }).catch(error => console.error(error)); }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            searchUser();
        }
    };
    const Lang = () => localStorage.getItem("lan") ?? "en";

    useEffect(() => { onLoadPage() }, []);
    const [test, setTest] = useState(false);

    if(loading) return <Loading/>
    return (
        <main className="edit-users">
            <h1 className='title'>{"Buscar usuario"}</h1> 
            <div className='searcher'>
                <span>{t("Introduce el Correo Electronico o el ID del usuario")}</span>
                <input type="text" style={{maxWidth:"100%", width:"100%"}} name="email" id="" onChange={ (e)=> onChangeUserQuery(e.target.value)} onKeyDown={handleKeyDown}/>
                <input type='button' value={"Buscar"} onClick={searchUser}/>
            </div>
            { currentUser && <>
                <div>
                    <h1>{"Edicion Actual"}</h1>
                    <div className='current-edit'>
                        <span>{`E-mail: ${currentUser?.email}`}</span>
                        <span>{`UID: ${currentUser?.id}`}</span>
                    </div>
                    <div  className='access-control'>
                        <div>
                            <h2>{"Control de acceso"}</h2>
                            <Toggle value={currentUser?.isPremium} onChangeData={(e) => onChangeData("isPremium", e.target.checked)} title='Is Premium'/>
                            <Toggle value={currentUser?.isAdmin} onChangeData={(e) => onChangeData("isAdmin", e.target.checked)} title='Is Admin'/>
                        </div>
                        
                    </div>
                </div>
                { currentUser?.subscriptions?.length > 0 && <h1>{"Suscripciones"}</h1>}
                <div className='subscriptions'>
                { currentUser?.subscriptions?.map((subscription:any) => (
                    <div>
                        <div className='content' key={subscription.subscription_id}>
                            <h2>{subscription.plan_name}</h2>
                            <span>{"Provider:"} {subscription.provider}</span>
                            <span>ID: {subscription.subscription_id}</span>
                            <div>Status:<span className={`${(getStatus(subscription.next_billing_time) === 'ACTIVE' ? "visible" : "invisible")} m-v-10`}>  {getStatus(subscription.next_billing_time)}</span></div>
                            <div>
                                <span>{"Producto:"}</span>
                                <select key={subscription.subscription_id} name="pricing" id="pricing" value={subscription.product_id} onChange={(e) => onChangeSubscriptionData(subscription, "product_id", e.target.value)}>
                                    {
                                        pricing?.map((p:any) => (<option value={ p.id }>{ p.title }</option>))
                                    }
                                </select>
                            </div>
                            <div>
                                <span>{"Valido hasta:"}</span>
                                <input type="datetime-local" value={toDate(subscription.next_billing_time) } onChange={(e) => onChangeSubscriptionData(subscription, "next_billing_time", Timestamp.fromDate(new Date(e.target.value)))} />
                            </div>
                        </div>
                    </div>
                ))
                }
                </div>
                <input type="button" value="Agregar Suscripcion" onClick={addSubscription}/>
                <input className='blue' type="button" value="Guardar" onClick={updateUser}/>
            </>
            }
        </main>
    )
}
