import './styles/main.css';
import { useTranslation } from 'react-i18next';

export default function Support() {
    const { t } = useTranslation();

    return (
                    <div className='area'>
                        <div className="home-area">
                            <br></br>
                            <div className='simulator-title'>{t("How does it work?")}</div>
                            <div className='simulations-image'>
                                <img style={{ "width": "100%", "maxWidth": "750px", borderRadius: "25px" }} src={process.env.PUBLIC_URL + "/img/orvo1.png"} />
                                <div>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 1</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Open fetoscopy number 1")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 2</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Explore the placental surface with the keyboard arrows")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 3</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Identify placental anastomoses")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 4</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Activate the laser with shift")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 5</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Perform laser coagulation of placental anastomoses with the space bar")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='simulations-image'>
                                <div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 6</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Unites coagulations performed with the Solomon technique")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 7</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Make a cagulation line between both placental edges")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 7</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Finish and review what you did and what you should have done")}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div>
                                        <h1 style={{ color: "white", fontWeight: "700", fontSize: "25px" }}>{t("Step")} 8</h1>
                                        <p style={{ color: "white", fontSize: "15px" }}>
                                            {t("Click Next to review your final score and the surgery time")}
                                        </p>
                                    </div>
                                </div>
                                <img style={{ "width": "100%", "maxWidth": "750px", borderRadius: "25px" }} src={process.env.PUBLIC_URL + "/img/orvo2.png"} />
                            </div>
                            <div className='home-third-area'>
                                <div className='contact-us'>
                                    <div className='contact-us-container'>
                                        <h1>{t("WriteUs")}</h1>
                                        <div className='circle-line'>
                                            <h2 className='circle-text' style={{ "fontWeight": "700" }}>{t("Need help?")}</h2>
                                            <div className="container"></div>
                                        </div>
                                        <div className='circle-line'>
                                            <div className="container" style={{ "maxWidth": "370px", "opacity": "0" }}></div>
                                            <div>{t("Contact us write")}</div>
                                        </div>
                                        <br></br>
                                        <div className='circle-line'>
                                            <h2 className='circle-text' style={{ "opacity": "0" }}>{t("SayHello")}</h2>
                                            <div className="container" style={{ "maxHeight": "0" }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='contact-us-form'>

                                    <div>
                                        <br />
                                        <div className="section-title">
                                            <form
                                                action="https://formsubmit.co/medicinafetal912@gmail.com"
                                                method="POST"
                                            >
                                                <input
                                                    className="form-styling"
                                                    type="text"
                                                    name="Nombre"
                                                    placeholder={t("Full name")}
                                                    required
                                                />
                                                <input
                                                    className="form-styling"
                                                    type="email"
                                                    name="Email"
                                                    placeholder={t("Email")}
                                                    required
                                                />
                                                <textarea
                                                    className="form-styling"
                                                    name="Pregunta"
                                                    placeholder={t("Leave us your question")}
                                                    style={{ height: "120px" }}
                                                    required
                                                ></textarea>
                                                <input type="hidden" name="_template" value="box"></input>
                                                <div className="button-container">
                                                    <div>
                                                        <button type="submit" className="button button-cta">
                                                            {t("Send")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    );
}
