import React, { useState, ReactNode } from 'react';
import './styles/expandableBox.css';

interface ExpandableBoxProps {
  children: ReactNode;
  title: string;
  index: number;
  active: boolean;
  isAdmin: boolean;
  onTitleChange: (newTitle: string) => void;
  onActiveChange: (newValue: boolean) => void;
  onDelete: ()=> void;
}

const ExpandableBox: React.FC<ExpandableBoxProps> = ({ children, title, index, active, isAdmin, onTitleChange, onActiveChange, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`expandable-box ${isExpanded ? 'expanded' : ''}`}>
      <div className='box-header'>
        <div className='left-content'>
          <span onClick={() => setIsExpanded(!isExpanded)}>{index}</span>
          {isAdmin ? <input type="text" value={title} onChange={(e) => onTitleChange(e.target.value)} /> : <p>{title}</p>}
        </div>
        <div className='right-content'>
          {isAdmin && <input type="checkbox" name="" id="" checked={active} onChange={(e) => onActiveChange(e.target.checked)} />}
          {isAdmin && <img src={process.env.PUBLIC_URL + "/img/delete.png"} onClick={onDelete} />}
          <img style={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)' }} src={process.env.PUBLIC_URL + "/img/dropdown.png"} onClick={() => setIsExpanded(!isExpanded)} />
        </div>
      </div>
      {isExpanded && <div>{children}</div>}
    </div>
  );
};

export default ExpandableBox;
