import './styles/main.css';
import './styles/courses.css';
import './common/styles/CourseDescription.css'
import React, { useEffect, useState, useRef } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import CourseTop from './common/CourseTop';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Module from './common/Module';

export default function Courses() {
    const { t } = useTranslation();
    return (
        <div className='area'>
            <CourseTop/>
            <br></br>
            <Tabs defaultIndex={0} className="course-tabs">
                <Module/>
            </Tabs>
        </div>
    );
}