import './styles/toggle.css'

interface IToggle {
    title:string;
    value:boolean;
    onChangeData:(e:any) => void;
}
const Toggle: React.FC<IToggle> = ({title, value, onChangeData}) => {

    return (
        <div className='toggle'>
            <span>{title}</span>
            <label className="switch">
                <input type="checkbox" checked = {value} onChange={(e) => onChangeData(e)}/>
                <span className="slider round"/>
            </label>
        </div>
    )
}
export default Toggle;
