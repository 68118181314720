import { Outlet, useNavigate } from 'react-router-dom';
import './styles/Header.css'
import './styles/Footer.css'
import Header from './Header';
import Footer from './Footer';


export function Layout() {
  return (
    <>
      <Header/>
      <Outlet />
      <Footer/>
    </>
  );
}