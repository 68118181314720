import { useEffect, useState } from 'react';
import './styles/Module.css'

function Module() {
    const [casesState, setCaseState] = useState('hide');
    const [dropdownState, setDropState] = useState('dropdown-closed');

    const dropdownAction = () => {
        setCaseState((casesState) => (casesState === "hide" ? "cases" : "hide"));
        setDropState((dropdownState) => (dropdownState === "dropdown-open" ? "dropdown-closed" : "dropdown-open"));
    }

    return (
        <div className='module'>
            <div className='module-container'>
                <div className='module-content'>
                    <div className='module-header'>
                        <div className='module-info' onClick={() => {dropdownAction() }}>
                            <div className='index' onClick={() => {dropdownAction() }}>0</div>
                            <div className='h1'>modulo</div>
                            <img className={dropdownState} src={process.env.PUBLIC_URL + '/img/dropdown.png'} alt="Arrow"></img>
                        </div>
                    </div>
                    
                </div>
                <div className={casesState}>
                    <div className='add-button add-case'>
                        <img src={process.env.PUBLIC_URL + '/img/add_icon.png'} alt="add" width="36.67px" height="36.67px"></img>
                        <div className='add-module-items'>Añadir nuevo caso</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Module;